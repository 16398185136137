const firebaseConfig = {
  projectId: "quarry-teipz",
  appId: "1:609154891940:web:a539f084860e5714706631",
  databaseURL:
    "https://quarry-teipz-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "quarry-teipz.firebasestorage.app",
  apiKey: "AIzaSyDvLjfRdFCe9m2S5O3G2GxTvIyDdekPVeM",
  authDomain: "quarry-teipz.firebaseapp.com",
  messagingSenderId: "609154891940",
};

export default firebaseConfig;
